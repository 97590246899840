<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "HeaderComponent",
  data: function () {
    return {
      isVisible: false,
      isMenuVisible: false,
    }
  },
  computed: {
    currentRoute() {
      return this.$route.path; // Get the current route path
    },
    ...mapState(['wallet_address']), // 映射 state
    connected() {
      if (this.wallet_address) {
        return this.wallet_address.substr(0, 6) + '...' + this.wallet_address.substr(-4)
      } else {
        return 'Connect Wallet'
      }
    }
  },
  methods: {
    ...mapActions(['connect_wallet', 'disconnect']), // 映射 actions
    onClickWallet: function () {
      this.isVisible = true;
    },
    onClickClose: function () {
      this.isVisible = false;
    },
    onClickMetamask: async function () {
      if (this.wallet_address) {
        this.disconnect()
      } else {
        await this.connect_wallet('metamask');
        this.isVisible = false;
      }
    },
    onClickOxk: async function () {
      await this.connect_wallet('okx');
      this.isVisible = false;
    },
    onClickGate: async function () {
      await this.connect_wallet('gate');
      this.isVisible = false;
    },
    onClickMenu: function () {
      this.isMenuVisible = !this.isMenuVisible
    }
  },
  mounted() {

  }
}
</script>

<template>
  <div>
    <div class="wap_nav wap">
      <div class="mobile">
        <div class="mobile-inner">
          <div class="mobile-inner-header">
            <div class="logo">
              <router-link to="/"><img src="images/logo.png" alt=""></router-link>
            </div>
            <div class="mobile-inner-header-icon mobile-inner-header-icon-out" @click="onClickMenu">
              <span></span><span></span></div>
          </div>
          <div class="mobile-inner-nav" v-show="isMenuVisible">
            <ul>
              <li class="h2tit" :class="{ current: currentRoute === '/' }">
                <h2 class="h2tit">
                  <router-link to="/">board</router-link>
                </h2>
              </li>
              <li class="h2tit" :class="{ current: currentRoute === '/create_token' }">
                <h2 class="h2tit">
                  <router-link to="/create_token">Create Token</router-link>
                </h2>
              </li>
              <li class="h2tit" :class="{ current: currentRoute === '/launchPad' }">
                <h2 class="h2tit">
                  <router-link to="/launchPad">LaunchPad</router-link>
                </h2>
              </li>
              <li class="h2tit" :class="{ current: currentRoute === '/ranking' }">
                <h2 class="h2tit">
                  <router-link to="ranking">Ranking</router-link>
                </h2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="wrap">
        <div class="logo pc">
          <router-link to="/">
            <img src="images/logo.png" alt="">
          </router-link>
        </div>
        <div class="right">
          <div class="nav pc">
            <ul>
              <li :class="{ current: currentRoute === '/' }">
                <h2 class="h2tit">
                  <router-link to="/">board</router-link>
                </h2>
              </li>
              <li :class="{ current: currentRoute === '/create_token' }">
                <h2 class="h2tit">
                  <router-link to="/create_token">Create Token</router-link>
                </h2>
              </li>
              <li :class="{ current: currentRoute === '/launchPad' }">
                <h2 class="h2tit">
                  <router-link to="/launchPad">LaunchPad</router-link>
                </h2>
              </li>
              <li :class="{ current: currentRoute === '/ranking' }">
                <h2 class="h2tit">
                  <router-link to="ranking">Ranking</router-link>
                </h2>
              </li>
            </ul>
          </div>
          <div class="ris">
            <div class="link1">
              <a href="https://33bnb-meme.gitbook.io/33bnb.meme-docs" target="_blank"><img src="images/im1.png" alt=""></a>
              <a href="#"><img src="images/im2.png" alt=""></a>
              <a href="#"><img src="images/im3.png" alt=""></a>
              <a href="https://x.com/33BNB_MEME" target="_blank"><img src="images/im4.png" alt=""></a>
            </div>
            <!--            <div class="link2">-->
            <!--              <h2 class="h2tit">-->
            <!--                <a href="#">En</a>-->
            <!--              </h2>-->
            <!--            </div>-->
            <div class="link3">
              <a href="javascript:;" @click="onClickMetamask">
                <span>{{ connected }}</span>
                <b><img src="images/qb.png" alt=""></b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>