<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import {ElMessage} from "element-plus";
import {mapState} from "vuex";
import {getSigner} from "@/utils/wallet";
import {AINPC_ABI} from "@/store/AINPC_ABI";
import {ethers} from "ethers";
import axios from "@/utils/axios";
import {short_address} from "../utils/utils";

const zero = '0x0000000000000000000000000000000000000000'
// const ainpc_address = '0x06A5b5f3E0fAc6ceBE15EA894Df2F6F04bb91401';
const ainpc_address = '0x65e10B69aA23D0d93965Ef103ce8E455B18eDD26';


export default {
  name: "AirDropView",
  components: {FooterComponent, HeaderComponent},
  data: function () {
    return {
      carousels: [
        // {
        //   imageUrl: "/images/pic3.png",
        // },
        // {
        //   imageUrl: "/images/2.png",
        // },
        // {
        //   imageUrl: "https://33bnb.meme/images/banner2.png",
        // }
      ],
      amount: 1,
      rewards: 0,
      invitor: zero,
      loading1: false,
      loading2: false,
      targetDate: new Date('2026-12-31 23:59:59+8'), // 目标日期和时间
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervalId: null,
      currentYear: 0,
      currentMonth: 0,
      currentDay: 0,
      currentHour: 0,
      currentMinute: 0,
      currentSecond: 0,

      user_rank: [],
      total_user: 0,
      total_point: 0
    }
  },
  computed: {
    ...mapState(['wallet_address', 'wallet_type']), // 映射 state
  },
  methods: {
    short_address,
    updateCurrentTime() {
      const now = new Date();
      this.currentYear = now.getFullYear();
      const monthAbbreviations = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      this.currentMonth = monthAbbreviations[now.getMonth()];
      this.currentDay = now.getDate();
      this.currentHour = now.getHours();
      this.currentMinute = now.getMinutes();
      this.currentSecond = now.getSeconds();
      // console.log(this.currentSecond)
    },
    // // 获取当前年份
    // currentYear() {
    //   return new Date().getFullYear();
    // },
    // // 获取当前月份（注意：返回值是 0 - 11，代表 1 月到 12 月，通常加 1 显示正确月份）
    // currentMonth() {
    //   return new Date().getMonth() + 1;
    // },
    // // 获取当前日期
    // currentDay() {
    //   return new Date().getDate();
    // },
    // // 获取当前小时
    // currentHour() {
    //   return new Date().getHours();
    // },
    // // 获取当前分钟
    // currentMinute() {
    //   return new Date().getMinutes();
    // },
    // // 获取当前秒数
    // currentSecond() {
    //   return new Date().getSeconds();
    // },
    // 计算时间差
    calculateTimeDifference() {
      const now = new Date();
      const diff = this.targetDate - now;
      if (diff <= 0) {
        this.years = 0;
        this.months = 0;
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
        clearInterval(this.intervalId);
        this.intervalId = null;
        // alert('倒计时结束！');
        return;
      }
      const totalSeconds = Math.floor(diff / 1000);
      const totalMinutes = Math.floor(totalSeconds / 60);
      const totalHours = Math.floor(totalMinutes / 60);
      const totalDays = Math.floor(totalHours / 24);
      // 简单计算年和月（不考虑闰年和每月天数差异）
      this.years = Math.floor(totalDays / 365);
      this.months = Math.floor((totalDays % 365) / 30);
      this.days = totalDays % 365 % 30;
      this.hours = totalHours % 24;
      this.minutes = totalMinutes % 60;
      this.seconds = totalSeconds % 60;
    },
    // 开始倒计时
    startCountdown() {
      if (this.intervalId) return;
      this.calculateTimeDifference();
      this.intervalId = setInterval(() => {
        this.calculateTimeDifference();
      }, 1000);
    },
    async onClickMint() {

      if (!this.wallet_address) {
        ElMessage.error({
          message: 'Please connect wallet first.',
          duration: 3000,
          center: true,
        });
        return;
      }
      if (this.loading1) {
        return
      }
      this.loading1 = true

      try {

        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        const contract = new ethers.Contract(ainpc_address, AINPC_ABI, signer)

        const value = ethers.parseEther((this.amount * 0.1).toString());
        console.log(value)

        //检查用户balance是否足够
        const userEthBalance = await provider.getBalance(this.wallet_address);
        if (value > userEthBalance) {
          ElMessage.error({
            message: 'Insufficient bnb balance.',
          })
          this.loading1 = false
          return
        }


        const tx = await contract.mint(this.invitor, {
          value: value
        });

        console.log('tx', tx);

        ElMessage.success({
          message: 'send tx: ' + tx.hash,
        });

        await tx.wait();
        ElMessage.success({
          message: 'Mint success.',
          duration: 3000,
          center: true,
        });

      } catch (e) {
        // ElMessage.error('Error: ' + e);
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        } else {
          ElMessage.error(e)
        }
      } finally {
        this.loading1 = false
      }
    },
    async onClickCollect() {
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }

      if (this.loading2 == true) {
        return
      }

      this.loading2 = true

      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        const contract = new ethers.Contract(ainpc_address, AINPC_ABI, signer)

        const tx = await contract.collectReward();

        console.log('tx', tx);

        ElMessage.success({
          message: 'send tx: ' + tx.hash,
        });

        await tx.wait();
        ElMessage.success({
          message: 'Mint success.',
          duration: 3000,
          center: true,
        });

      } catch (e) {
        // ElMessage.error('Error: ' + e);
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        }
      } finally {
        this.loading2 = false
      }

    },
    async loadReward() {
      if (!this.wallet_address) {
        return;
      }

      const signerInfo = await getSigner(this.wallet_type); // Get the signer information
      // Check if signerInfo is an array with two elements
      let provider, signer;
      if (Array.isArray(signerInfo) && signerInfo.length === 2) {
        [provider, signer] = signerInfo;
      } else {
        throw new Error('getSigner() did not return an array with provider and signer');
      }

      const contract = new ethers.Contract(ainpc_address, AINPC_ABI, signer)
      const rewardRaw = await contract.rewards(this.wallet_address);
      this.rewards = ethers.formatEther(rewardRaw);
      console.log("rewards", this.rewards)
    },
    onClickCopy() {
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }
      const url = "https://33bnb.meme/launchPad?invitor=" + this.wallet_address;
      navigator.clipboard.writeText(url);
      ElMessage.success('Copy success');
    },
    async load() {
      const load_carousel_resp = await axios.get('/api/carousel/airdrop');
      if (load_carousel_resp.data.code === 0) {
        this.carousels = load_carousel_resp.data.data.carousels;
      }
      const user_rank_resp = await axios.get('/api/user_rank');
      if (user_rank_resp.data.code === 0) {
        this.user_rank = user_rank_resp.data.data.user_list;
        this.total_user = user_rank_resp.data.data.total_user;
        this.total_point = user_rank_resp.data.data.total_point;
      }
    },
    async loadUser() {
      const user_resp = await axios.get('/api/user/' + this.wallet_address);
      if (user_resp.data.code === 0) {
      }
    }
  },
  mounted() {
    this.startCountdown()
    this.load()
    const invitor = new URLSearchParams(window.location.search).get("invitor");
    console.log("invitor", invitor)
    if (invitor) {
      this.invitor = invitor
      localStorage.setItem('invitor', invitor)
    }
    this.timer1 = setInterval(() => {
      this.loadReward()
    }, 3000)
    this.timer2 = setInterval(() => {
      this.updateCurrentTime()
    }, 1000)
  },
  unmounted() {
    clearInterval(this.timer1)
    clearInterval(this.timer2)
  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section8">
        <div class="wrap">
          <div class="left">

            <!--            <div class="swiper-container">-->
            <!--              <div class="swiper-wrapper">-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner2.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner2.png" alt="">-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="swiper-pagination"></div>-->
            <!--            </div>-->

            <el-carousel class="el-carousel">
              <el-carousel-item class="el-carousel-item" v-for="carousel in carousels">
                <img :src="carousel.imageUrl" alt="">
              </el-carousel-item>
            </el-carousel>

          </div>
          <div class="right">
            <div class="ms1">
              <div class="s1">Points <br> Leaderboard</div>
              <div class="s2">
                <div class="m1">{{ currentMonth }} {{ currentDay }} | {{ currentYear }}</div>
                <div class="m2"> {{ currentMinute }}:{{ currentSecond }}</div>
              </div>
            </div>
            <div class="line"><img src="images/lineimg.png" alt=""></div>
            <div class="ms2">
              <p>
                <span class="s1">Total Points</span>
                <span class="s2">{{ Math.floor(total_point) }}</span>
              </p>
              <p>
                <span class="s1">Users</span>
                <span class="s2">{{ total_user }}</span>
              </p>
            </div>
            <div class="ms3">
              <div class="title">
                <div class="s1">Rank</div>
                <div class="s2">
                  <p>Address</p>
                  <p>Points</p>
                </div>
              </div>
              <div class="list">
                <div class="item " v-for="(item, index) in  user_rank" :key="item.walletAddress">
                  <div class="s1">{{ index + 1 }}</div>
                  <div class="s2 orange">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">{{ short_address(item.walletAddress) }}</div>
                    </div>
                    <div class="m2">{{ Math.floor(item.point) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section9">
        <div class="wrap">
          <div class="title">AINPC donation</div>
          <div class="content">
            <div class="s1">
              <div class="lef">
                <div class="icon"><img src="images/ic2d.png" alt=""></div>
                <input type="number" max="100" min="1" v-model="amount" class="words">
              </div>
              <button class="btn" @click="onClickMint" v-loading="loading1" style="background-color: #fcd251">Mint Now
              </button>
            </div>
            <div class="s2">
              <button class="btn1" @click="onClickCollect" v-loading="loading2">Collect：{{ rewards }} BNB</button>
              <button class="btn2" @click="onClickCopy">Invitation</button>
            </div>
          </div>
          <div class="words">Available Time : 12/02 0:00 - 12/31 23:59 (UTC+0)</div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>
.el-carousel {
  width: 100%;
  height: auto; /* 允许高度自适应 */
  aspect-ratio: 2100 / 1170; /* 保持宽高比 */
  //margin-bottom: 16px;
  border-radius: 20px;
}

.el-carousel-item {
  width: 100%;
  height: auto; /* 允许高度自适应 */
  aspect-ratio: 2100 / 1170; /* 保持宽高比 */
}

.el-carousel img {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 允许高度自适应 */
  object-fit: fill; /* 保持图片比例，裁剪多余部分 */
}

.el-message {
  z-index: 99999 !important;
}
</style>
