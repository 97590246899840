<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import axios from "@/utils/axios";
import router from "@/router";

export default {
  name: "RankingView",
  components: {FooterComponent, HeaderComponent},
  data: function () {
    return {
      page: 1,
      pageSize: 10,
      token_list: [],
      eth_price: 0,
      totalPages: 0
    }
  },
  computed: {},
  methods: {
    async load() {
      const load_resp = await axios.post('/api/token_list', {
        page: this.page,
        pageSize: this.pageSize,
        rank: 'progress'
      })
      if (load_resp.data.code === 0) {
        this.token_list = load_resp.data.data.tokens
        this.eth_price = load_resp.data.data.eth_price;
        this.totalPages = Math.ceil(load_resp.data.data.total / this.pageSize); // Calculate total pages
      }
    },
    toDetail(tokenAddress) {
      router.push('/detail?token=' + tokenAddress)
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section7">
        <div class="wrap">
          <div class="title">
            <div class="tit">Progress Ranking</div>
            <div class="ris">
              <p>24 Hours Gainers Ranking</p>
              <p>MarketCap Ranking</p>
              <p>24 Hours Trading Volume</p>
            </div>
          </div>
          <div class="dot">
            <b class="current"></b>
            <b></b>
            <b></b>
            <b></b>
          </div>
          <div class="items">
            <div class="item" v-for="(item,index) in token_list" :key="item.id" @click="toDetail(item.address)">
              <div class="tit">${{ (item.market_cap_in_r64 * eth_price).toFixed(2) }}</div>
              <div class="val">{{ item.launch_progress.toFixed(2) }}%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">{{ item.symbol }}</div>
                    <div class="icon2">
                      <img :src="item.image_url" alt="" class="icon_img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="number">{{ index + 1 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>
.icon_img {
  border-radius: 50% !important; /* 将图片变成圆形 */
  object-fit: cover; /* 确保图片覆盖整个圆形区域 */
  overflow: hidden; /* 隐藏超出圆形区域的部分 */
  aspect-ratio: 1 / 1 !important; /* 设置宽高比为 1:1 */
}
</style>